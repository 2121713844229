<template>
  <div class="mcontainer">
    <div class="breadcrumb-area py-0">
        <div class="breadcrumb">
            <ul class="m-0">
                <li>
                    <router-link to="/app/news">News</router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'DetailNews', params: {code: slug } }">{{ form.title }}</router-link>
                </li>
                <li class="active">
                    Update
                </li>
            </ul>
        </div>
    </div>
    <div class="max-w-5xl m-auto shadow-md rounded-md bg-white mt-5">
        <form @submit.prevent="actionSubmit()">
            <div class="text-center border-b py-4">
                <h3 class="font-bold text-lg"> Update News </h3>
            </div>
            <div class="p-7 space-y-5">
                <input class="form-control" v-model="form.id" type="hidden" value="" autocomplete="off">
                <div>
                    <label class="font-semibold">Title</label><br>
                    <input class="form-control" v-model="form.title" type="text" value="" autocomplete="off" required>
                </div>
                <div>
                    <label class="font-semibold">Type</label>
                    <select v-model="form.type" class="form-control" required>
                        <option value="">Select Type</option>
                        <option value="hall_of_fame">Hall of Fame</option>
                        <option value="article">Infinity News</option>
                    </select>
                </div>
                <div>
                    <label class="font-semibold">Category</label>
                    <select v-model="form.category" class="form-control" required>
                        <option value="">Select Category</option>
                        <option :value="category.id" v-for="category in categories" :key="category.id">{{ category.title }}</option>
                    </select>
                </div>
                <div>
                    <label class="font-semibold">Description</label><br>
                    <!-- <textarea class="form-control h-32" value="" v-model="form.description" type="text" autocomplete="off"></textarea> -->
                    <Editor v-model="form.description" />
                </div>
                <div>
                    <label class="font-semibold">Tags</label><br>
                    <vue-tags-input v-model="tags" :tags="form.tags" @tags-changed="newTags => form.tags = newTags" class="form-control" />
                </div>
                <div>
                    <label class="font-semibold">Preview</label><br>
                    <div class="image-uploader">
                        <input type="file" ref="preview" @change="onFileSelected($event, 'preview')">
                        <span @click="$refs.preview.click()"><Icon icon="bi:cloud-upload" /></span>
                        <img :src="preview ? preview : $placeholder2" alt="Preview" class="w-80">
                    </div>
                </div>
            </div>
            <!-- form footer -->
            <div class="border-t flex justify-center border-gray-100 p-6">
                <button class="lg:w-1/3 h-9 lg:px-5 rounded-md bg-blue-600 text-white space-x-1.5 px-5" :class="disable_submit">
                    <span v-if="disable_submit == ''">Update Now</span>
                    <b-spinner v-if="disable_submit == 'disabled'" label="Loading..." small />
                </button>
            </div>
        </form>
    </div>
  </div>
</template>
<script>
    import axios from 'axios'
    import { Icon } from '@iconify/vue2'
    import Editor from '@/components/Editor'
    import VueTagsInput from '@johmun/vue-tags-input'

    export default {
        name: 'update_resource',
        components: {
            Icon,
            Editor,
            VueTagsInput
        },
        data () {
            return {
                tags: '',
                categories: [],
                form: {
                    id: 0,
                    title: '',
                    type: '',
                    tags: [],
                    description: '',
                    category: '',
                    preview: ''
                },
                preview: '',
                slug: '',
                disable_submit: ''
            }
        },
        async created () {
            try {
                const result = await axios.get('category?type=news')
                this.categories = result.data.result
                const res = await axios.get('article/' + this.$route.params.code)
                this.form.id = res.data.result.id
                this.form.title = res.data.result.title
                this.form.type = res.data.result.type
                this.form.category = res.data.result.category.id
                this.form.description = res.data.result.description
                this.preview = res.data.result.images
                this.slug = res.data.result.slug

                if (res.data.result.tags !== '') {
                    const tags = res.data.result.tags.split(',')
                    for (let index = 0; index < tags.length; index++) {
                        this.form.tags.push({ text: tags[index] })
                    }
                }
            } catch (e) {
                console.error(e)
            }
        },
        methods: {
            actionSubmit () {
                this.disable_submit = 'disabled'
                const $this = this
                const formData = new FormData()
                Object.keys(this.form).forEach(e => {
                    if (e === 'tags') {
                        formData.append(e, JSON.stringify(this.form[e]))
                    } else {
                        formData.append(e, this.form[e])
                    }
                })
                axios.post('article/update', formData).then(function (response) {
                    $this.disable_submit = ''
                    $this.$swal({
                        imageUrl: require('@/assets/images/vector-success.png'),
                        imageWidth: 400,
                        title: 'Berhasil Update News',
                        text: response.data.message
                    }).then(function () {
                        $this.$router.push({ name: 'DetailNews', params: { code: $this.slug } })
                    })
                }).catch(function () {
                    $this.disable_submit = ''
                    $this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something Wrong'
                    })
                })
            },
            onFileSelected (e, target) {
                this.form[target] = e.target.files[0]
                this[target] = URL.createObjectURL(this.form[target])
            }
        }
    }
</script>
